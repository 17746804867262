import { useContentSchema } from '@/data/Content/ContentSchema';
import { usePageDataFromId } from '@/data/_PageDataFromId';
import { ID } from '@/data/types/Basic';
import Head from 'next/head';
import { FC } from 'react';

export const MicroDataContent: FC<{ id: ID;}> = ({}) => {
	const { data, loading } = usePageDataFromId();
	const seoTitle = data?.page?.title ?? '';
	const identifier = data?.identifier ? data?.identifier : '';
	const { data: contentSchema, isLoading: isSchemaRespLoading } = useContentSchema(
		!loading ? { seoTitle, identifier } : null
	);

	return (
		<>
			{!isSchemaRespLoading ? (
				<Head>
					<script
						type="application/ld+json"
						dangerouslySetInnerHTML={{ __html: JSON.stringify(contentSchema) }}
					/>
				</Head>
			) : null}
		</>
	);
};
