/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { transactionsContentSchema } from 'integration/generated/transactions';
import {
    ComIbmCommerceContentschema,
    ComIbmCommerceContentschemahandler,
} from 'integration/generated/transactions/data-contracts';
import { useSettings } from '@/data/Settings';
import { RequestParams } from 'integration/generated/transactions/http-client';
import useSWR from 'swr';

const contentSchemaFetcher =
	(pub: boolean) =>
	async (
		storeId: string,
		data: ComIbmCommerceContentschema,
		params: RequestParams = {}
	): Promise<ComIbmCommerceContentschemahandler | undefined> =>
		await transactionsContentSchema(pub).microDataContentCreate(storeId, data, params);

export const useContentSchema = (schemaProps: ComIbmCommerceContentschema | null) => {
	const { settings } = useSettings();
	const {seoTitle = null, identifier = null} = schemaProps ?? {};
	const {
		data,
		error,
		isLoading,
	} = useSWR(
		settings?.storeId && seoTitle && identifier ? [{ storeId: settings?.storeId, seoTitle, identifier }] : null,
		async ([{ storeId, seoTitle, identifier }]) => contentSchemaFetcher(true)(storeId, {seoTitle, identifier})
	);

	return {
		data,
		error,
		isLoading,
	};
};
